:root {
  --primary-color: #000000;
  --secondary-color: #333333;
  --background-color: #ffffff;
  --text-color: #000000;
  --card-background: #ffffff;
  --shadow-subtle: 0 4px 6px rgba(0, 0, 0, 0.08);
  --shadow-hover: 0 10px 20px rgba(0, 0, 0, 0.12);
  --accent-color: #777777;
  --border-color: #e0e0e0;
  --select-background: #f9f9f9;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

header {
  text-align: center;
  /* padding: 40px 0; */
  /* margin-bottom: 30px; */
  border-bottom: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

header h1 {
  font-size: 2.8rem;
  font-weight: 700;
  color: var(--primary-color);
  letter-spacing: -1px;
  position: relative;
  display: inline-block;
}

header h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 3px;
  background-color: var(--primary-color);
}

.search-form {
  display: flex;
  /* margin-bottom: 40px; */
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--shadow-subtle);
  flex-wrap: wrap;
}

.search-form input {
  flex-grow: 2;
  padding: 15px;
  font-size: 1rem;
  border: none;
  background-color: white;
  transition: all 0.3s ease;
  min-width: 200px;
}

.search-form input:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.search-form button {
  padding: 15px 25px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.search-form button:hover {
  background-color: var(--secondary-color);
}

.date-selector,
.language-selector {
  position: relative;
}

.date-selector select,
.language-selector select {
  appearance: none;
  padding: 15px;
  font-size: 1rem;
  border: none;
  border-left: 1px solid var(--border-color);
  background-color: var(--select-background);
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  min-width: 120px;
}

.date-selector select:focus,
.language-selector select:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0.03);
}

.filter-container {
  display: flex;
  border-left: 1px solid var(--border-color);
}

.date-selector, .language-selector {
  position: relative;
}

.date-selector select, .language-selector select {
  border-left: 1px solid var(--border-color);
  min-width: 120px;
}

.date-selector select {
  border-left: none;
}
.date-selector::after,
.language-selector::after {
  content: '▼';
  font-size: 0.8rem;
  color: var(--accent-color);
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.news-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.news-list h2 {
  font-size: 1.5rem;
  font-weight: 600;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}

.news-item {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 25px;
  box-shadow: var(--shadow-subtle);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--border-color);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.news-item:hover {
  box-shadow: var(--shadow-hover);
  transform: translateY(-3px);
}

.news-item h3 {
  font-size: 1.3rem;
  color: var(--text-color);
  margin-bottom: 10px;
  line-height: 1.4;
}

.news-item p {
  color: var(--secondary-color);
  margin-bottom: 15px;
  font-size: 0.95rem;
}

.news-meta {
  font-size: 0.85rem;
  color: var(--accent-color);
  margin-bottom: 15px;
}

.news-item a {
  align-self: flex-start;
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 600;
  transition: all 0.3s ease;
  padding: 8px 15px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

.news-item a:hover {
  background-color: var(--primary-color);
  color: white;
}

footer {
  text-align: center;
  padding: 30px 0;
  margin-top: auto;
  color: var(--accent-color);
  border-top: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer-stats {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
}

.github-stat {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
  transition: all 0.2s ease;
  text-decoration: none;
  color: var(--accent-color);
  padding: 6px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.github-stat:hover {
  color: var(--primary-color);
  background-color: rgba(0, 0, 0, 0.03);
  border-color: var(--secondary-color);
}

.github-stat svg {
  width: 16px;
  height: 16px;
}

.github-link {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 500;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.github-link:hover {
  text-decoration: underline;
}

.github-link svg {
  width: 18px;
  height: 18px;
}

.error-message {
  font-size: 0.8rem;
  color: #777;
  margin-top: 5px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 1.2rem;
  color: var(--primary-color);
  position: relative;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9rem;
  letter-spacing: 1px;
  margin-top: 20px;
  animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .app {
    padding: 15px;
  }

  header {
    padding: 30px 0;
  }

  header h1 {
    font-size: 2.2rem;
  }

  .search-form {
    flex-direction: column;
  }
  .filter-container {
    flex-direction: column;
    width: 100%;
    border-left: none;
  }

  .date-selector select, .language-selector select {
    border-left: none;
    border-top: 1px solid var(--border-color);
  }
  .search-form input,
  .search-form button,
  .date-selector select,
  .language-selector select {
    width: 100%;
    border-left: none;
    border-top: 1px solid var(--border-color);
  }

  .search-form input {
    border-top: none;
  }

  .search-form button {
    border-radius: 0 0 8px 8px;
  }

  .news-item {
    padding: 20px;
  }

  .footer-stats {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}